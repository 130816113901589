<xcc-card-container>
  <form [formGroup]="formGroup" id="xcc-student-account-form" content>
    <h3 class="panel-title xcc-header-spacing">Create Your Account</h3>
    <xcc-input
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="text"
      autocapitalize="on"
      autocomplete="given-name"
      [label]="firstNameLabel"
      controlName="firstName"
      [onBlur]="onBlurStudentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{ required: 'First name is required.', pattern: 'Use letters only.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="showLastNameOnAccountForm"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="text"
      autocapitalize="on"
      autocomplete="family-name"
      [label]="lastNameLabel"
      controlName="lastName"
      [onBlur]="onBlurStudentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{ required: 'Last name is required.', pattern: 'Use letters only.' }"
    >
    </xcc-input>
    <xcc-input
      [parentClass]="shouldShowPrivacyPolicy ? 'flex flex-col items-start items-stretch' : 'flex flex-col items-start items-stretch mb-4'"
      type="email"
      autocapitalize="off"
      autocomplete="email"
      [label]="emailAddressLabel"
      controlName="email"
      [onBlur]="onBlurStudentEmail"
      [formGroup]="formGroup"
      [infoIcon]="shouldShowInfoIcon"
      [infoTooltip]="emailInfoMessage"
      [errorMessage]="{ required: 'Email is required.', pattern: 'Enter a valid email address.', emailDomain: 'Invalid email domain' }"
    >
    </xcc-input>

    <p class="text-xs leading-disclaimer mb-4" *ngIf="userExists">
      {{ userExistInfoText }}
    </p>
    <!-- Privacy Policy link -->
    <p *ngIf="shouldShowPrivacyPolicy" class="text-xs leading-5 text-grey-600 mb-4">
      We hate spam as much as you do.&nbsp;
      <a
        class="text-xs leading-5 underline text-blue-600 cursor-pointer text-link"
        *ngIf="xccConfig | async as xccConfig"
        [attr.data-test]="'button-see-privacy-policy'"
        [href]="xccConfig.pageConfig.privacyPolicyLink"
        target="_blank"
        rel="noopener"
      >
        See our privacy policy.
      </a>
    </p>

    <xcc-input
      *ngIf="!userExists"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="on"
      [label]="confirmEmailAddressLabel"
      controlName="confirmEmail"
      [onBlur]=""
      [formGroup]="formGroup"
      [infoIcon]="false"
      [infoTooltip]=""
      [errorMessage]="{ required: 'Reenter your email address.', pattern: 'Email doesn\'t match.', notMatch: 'Email doesn\'t match.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="!userExists"
      parentClass="flex flex-col items-start items-stretch"
      isPasswordInput="true"
      type="password"
      autocapitalize="off"
      autocomplete="off"
      label="Create Password"
      controlName="password"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{required: 'Password is required.', pattern: passwordNotMatchMessage}"
      >
    </xcc-input>
  </form>
</xcc-card-container>
