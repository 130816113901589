<div class="p-xcc my-xcc-sm bg-white rounded-md font-primary text-grey-900">
  <form [formGroup]="formGroup" id="xcc-parent-account-form">
    <h3 class="panel-title xcc-header-spacing">Create Your Account</h3>
    <div class="h-20 w-full p-3 rounded-md bg-[#e9f3f9] flex-col justify-start items-start gap-3 inline-flex mb-4">
      <div class="flex-col justify-start items-start gap-0.5 flex">
        <div class="justify-start items-start gap-0.5 inline-flex">
        <div class="text-xl font-bold leading-tight">I am a...</div>
      </div>
      </div>
      <div class="justify-start items-start gap-4 inline-flex">
        <div class="justify-start items-center gap-2 flex">
          <div class="w-5 h-5 relative">
            <input class="w-5 h-5 form-radio checked:bg-form text-form cursor-pointer focus:ring-transparent focus-visible:ring-form" type="radio" id="role-student" formControlName="role" value="student" (change)="onRoleChange($event)" />
          </div>
          <label for="role-student" class="text-base font-bold leading-normal cursor-pointer">Student</label>
        </div>
        <div class="justify-start items-center gap-2 flex">
          <div class="w-5 h-5 relative">
            <input class="w-5 h-5 form-radio checked:bg-form text-form cursor-pointer focus:ring-transparent focus-visible:ring-form" type="radio" id="role-parent" formControlName="role" value="parent" (change)="onRoleChange($event)" />
          </div>
          <label for="role-parent" class="text-base font-bold leading-normal cursor-pointer">Parent</label>
        </div>
      </div>
    </div>

    <xcc-input
      *ngIf="iAmParent"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="text"
      autocapitalize="on"
      autocomplete="given-name"
      label="Parent First Name"
      controlName="parentFirstName"
      [onBlur]="onBlurParentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{ required: 'Parent first name is required.', pattern: 'Use letters only.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="iAmParent"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="email"
      label="Parent Email Address"
      controlName="teacherEmail"
      [onBlur]="onBlurParentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{
        required: 'Email is required.',
        pattern: 'Enter a valid email address.',
        emailIsEqualToParent: 'Parent email cannot be the same as the student email',
        emailDomain: 'Invalid email domain'
      }"
    >
    </xcc-input>

    <div class="w-full justify-start items-start gap-[9px] inline-flex" *ngIf="userExists && iAmParent">
      <div class="pt-0.5 justify-start items-center gap-2.5 flex">
          <div class="w-5 h-5 relative">
            <fa-icon class="text-blue-600" [icon]="faInfoCircle"></fa-icon>
          </div>
      </div>
      <div class="grow shrink basis-0 text-black text-base font-bold leading-normal">{{ userExistInfoText }}</div>
    </div>

    <xcc-input
      *ngIf="!userExists && iAmParent"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="on"
      label="Confirm Parent Email Address"
      controlName="confirmTeacherEmail"
      [onBlur]=""
      [formGroup]="formGroup"
      [infoIcon]="false"
      [infoTooltip]=""
      [errorMessage]="{ required: 'Reenter parent email address.', pattern: 'Email doesn\'t match.', notMatch: 'Email doesn\'t match.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="!userExists && iAmParent"
      parentClass="flex flex-col items-start items-stretch"
      isPasswordInput="true"
      type="password"
      autocapitalize="off"
      autocomplete="off"
      label="Parent Password"
      controlName="password"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{required: 'Password is required.', pattern: passwordNotMatchMessage}"
      >
    </xcc-input>

    <div *ngIf="iAmParent" class="h-5 w-full pt-3 pb-2 flex-col justify-start items-start gap-2.5 inline-flex my-4">
      <div class="self-stretch h-[0px] border border-[#afc1c6]"></div>
    </div>
    
    <xcc-input
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="text"
      autocapitalize="on"
      autocomplete="given-name"
      label="Student First Name"
      controlName="studentName"
      [onBlur]="onBlurStudentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{ required: 'First name is required.', pattern: 'Use letters only.' }"
    >
    </xcc-input>

    <xcc-input
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="email"
      label="Student Email Address"
      controlName="email"
      [onBlur]="onBlurStudentEmail"
      [formGroup]="formGroup"
      [infoIcon]="true"
      [infoTooltip]="emailInfoMessage"
      [errorMessage]="{ required: 'Email is required.', pattern: 'Enter a valid email address.', emailDomain: 'Invalid email domain', emailIsEqualToParent: 'Parent email cannot be the same as the student email', }"
    >
    </xcc-input>

    <div class="w-full justify-start items-start gap-[9px] inline-flex" *ngIf="userExists && !iAmParent">
      <div class="pt-0.5 justify-start items-center gap-2.5 flex">
          <div class="w-5 h-5 relative">
            <fa-icon class="text-blue-600" [icon]="faInfoCircle"></fa-icon>
          </div>
      </div>
      <div class="grow shrink basis-0 text-black text-base font-bold leading-normal">{{ userExistInfoText }}</div>
    </div>

    <xcc-input
      *ngIf="iAmParent || (!userExists && !iAmParent)"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="on"
      label="Confirm Student Email Address"
      controlName="confirmEmail"
      [onBlur]=""
      [formGroup]="formGroup"
      [infoIcon]="false"
      [infoTooltip]=""
      [errorMessage]="{ required: 'Reenter your email address.', pattern: 'Email doesn\'t match.', notMatch: 'Email doesn\'t match.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="!userExists && !iAmParent"
      parentClass="flex flex-col items-start items-stretch"
      isPasswordInput="true"
      type="password"
      autocapitalize="off"
      autocomplete="off"
      label="Student Password"
      controlName="password"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{required: 'Password is required.', pattern: passwordNotMatchMessage}"
      >
    </xcc-input>

    <p class="text-xs leading-5 mb-0 text-grey-600" [class]="iAmParent ? 'mt-2' : 'mt-4'">
      We hate spam as much as you do.&nbsp;
      <a
      class="text-xs leading-5 underline text-teal-700 cursor-pointer text-link"
        *ngIf="xccConfig | async as xccConfig"
        [attr.data-test]="'button-see-privacy-policy'"
        [href]="xccConfig.pageConfig.privacyPolicyLink"
        target="_blank"
        rel="noopener"
      >
        See our privacy policy.
      </a>
    </p>
  </form>
</div>
